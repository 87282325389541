html, body {
    height: 100%;
}

body {
    font-family: Arial, Helvetica, sans-serif;
}

.module {
    flex: 1;
}

.slot--vertical {
    flex-direction: column;
}

#splash-screen {
    display: none;
    position: absolute;
    top: 0;
    left: 0;

    &:not(:empty) {
        display: flex;
        width: 100%;
        height: 100%;
    }
}

.asset-loader,
.config-error {
    margin: auto;
    padding: 1em;
    background: white;
    border-radius: 3px;
    box-shadow: 3px 3px 10px rgba(0,0,0,0.5);

    &:before {
        content: "";
        display: block;
    }
}

.asset-loader {
    &:before {
        width: 91px;
        height: 12px;
        margin: 0 auto 0.5em;
        background: url("/images/808.svg");
    }
}

.config-error {
    font-size: 1.25em;
    text-align: center;
    color: white;
    background: #2a517f;

    &:before {
        width: 10em;
        height: 3em;
        margin: 0 auto 1em;
        background: url("/styles/default/images/next-logo.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

#update-check {
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 1000;
    max-width: 30em;
}
